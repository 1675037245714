import router from "next/router";
import React, { FC, useEffect } from "react";
import styles from "../../styles/Home.module.css";
import { LoadingSpinner } from "../components/LoadingSpinner";

const Home: FC = () => {
  useEffect(() => {
    router.push("/auth/login");
  }, []);

  return (
    <div className={styles.container}>
      <LoadingSpinner isVisible={true} />
    </div>
  );
};

export default Home;
